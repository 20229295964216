<template>
  <v-container class="px-0 custom-bottem-cuts">
    <v-row v-if="!!contact">
      <v-col cols="12" md="7" class="px-md-0 px-xs-2 my-6">
        <h2 class="px-2">
          {{ activeTranslation?.title.split(' ')[0] }}
          <span class="text-secondary">
            {{ activeTranslation?.title.split(' ')[1].split('?')[0] }}
          </span>
          ?
        </h2>
        <h2 class="px-2">{{ activeTranslation?.title.split('?')[1] }}</h2>
        <v-row class="d-flex justify-start mx-0 px-0 mt-8">
          <v-col
            v-for="person in contactPersons"
            :key="person?.name"
            cols="4"
            md="auto"
            offset-md="1"
            :class="{
              'ml-0 pl-0': person?.order === 1 && mdAndUp,
              'text-center px-0': smAndDown,
            }">
            <ContactAvatar :size="xlAndUp ? '195' : smAndUp ? '152' : '100'">
              <v-img
                :src="person?.picture"
                :srcset="`${person?.picture} 1x, ${person?.picture} 2x`"
                :alt="contact.contact_images_alt_text || ''" />
            </ContactAvatar>
            <p class="font-weight-bold mt-5 text-center">{{ person?.name }}</p>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="5" class="d-flex justify-end px-0 my-6 mx-0">
        <v-sheet
          color="secondary"
          width="100%"
          :height="xlAndUp ? '470' : smAndUp ? '400' : '300 '"
          :class="{ 'mx-0 px-0': true, 'rounded-t-xl': mdAndUp }">
          <div>
            <h2 class="mt-14 pt-xl-6 d-flex justify-center text-white">{{ t('contact') }}</h2>
            <p class="mt-8 text-center contact-box">
              {{ t('contact_hotline') }}
              <br />
              {{ activeTranslation?.day_from }} {{ t('until') }}
              {{ activeTranslation?.day_until }}
              {{ activeTranslation?.available_hours }}
            </p>
            <div class="contact-phone">
              <p class="mt-8 contact-box">
                <span>
                  <v-icon color="white" class="flip">mdi-phone-outline</v-icon>
                  <span class="font-weight-bold ml-4">
                    <a
                      :href="`tel:${activeTranslation?.phone}`"
                      class="text-decoration-none text-white">
                      {{ activeTranslation?.phone }}
                    </a>
                  </span>
                </span>
                <br />
                <span>
                  <v-icon color="white">mdi-email-outline</v-icon>
                  <span class="font-weight-bold ml-4">
                    <a :href="`mailto:${contact?.email}`" class="text-decoration-none text-white">
                      {{ contact?.email }}
                    </a>
                  </span>
                </span>
              </p>
            </div>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import type { LocaleObject } from '@nuxtjs/i18n'
import { useDisplay } from 'vuetify'
import type { Contact, ContactTranslation } from '~/types/types'
const { locale, locales, t } = useI18n()
const { smAndDown, mdAndUp, smAndUp, xlAndUp } = useDisplay()

const props = defineProps({
  contact: {
    type: Object as PropType<Contact>,
    default: () => ({}),
  },
})

const { contact } = toRefs(props)

const currentLocaleIso = computed(() => {
  return locales.value?.find((l: LocaleObject) => l.code === locale.value)?.language as string
})

const activeTranslation = computed(() => {
  return contact.value.translations?.find(
    (t: ContactTranslation) => t.languages_code === currentLocaleIso.value,
  )
})
const contactPersons = computed(() => {
  const persons = [
    {
      picture: contact.value.first_person_image
        ? `https://epd.directus.app/assets/${contact.value.first_person_image}?format=webp`
        : '',
      name: contact.value.first_person_name,
      order: 1,
    },
    {
      picture: contact.value.second_person_image
        ? `https://epd.directus.app/assets/${contact.value.second_person_image}?format=webp`
        : '',
      name: contact.value.second_person_name,
      order: 2,
    },
    {
      picture: contact.value.third_person_image
        ? `https://epd.directus.app/assets/${contact.value.third_person_image}?format=webp`
        : '',
      name: contact.value.third_person_name,
      order: 3,
    },
  ]
  return persons
})
</script>

<style lang="scss" scoped>
.custom-bottem-cuts {
  margin-bottom: -40px;
}

.contact-box {
  font-size: 28px;
  line-height: normal;
  i {
    font-size: 28px;
  }
}

.contact-phone {
  margin: 0 auto;
  width: fit-content;
}

@media (max-width: 599px) {
  .contact-box {
    font-size: 16px;
    line-height: normal;
    i {
      font-size: 16px;
    }
  }
}
</style>
